
export const csEmail = 'contact@cscommunications.net'
export const csTel = '(+237) 652 52 20 20'
export const csLocation = 'Douala, Cameroun'
export const csLocationLink = 'https://maps.app.goo.gl/uQoMFBVgZ7GBRiVL9'

export const csInstagram = 'https://www.instagram.com/cscommunicationssarl/'
export const csFacebook = 'https://www.facebook.com/cscommunications/about?locale=fr_FR'
export const csYoutube = 'https://www.youtube.com/@CSCOMMUNICATIONS'
export const csLinkedIn = 'https://www.linkedin.com/company/cs-communications/'

export const graphicLink = 'service-graphique'
export const videoLink = 'service-audiovisuel'
export const webLink = 'service-web'
export const socialLink = 'service-social-media'
export const textileLink = 'service-textile'
export const contactLink = 'contact'
export const realisationsLink = 'realisastions'