import React, { useEffect, useState } from 'react'

import SolutionsCarousel from '../components/SolutionsCarousel'
import { audiovisualSolutions, designSolutions, digitalSolutions, strategySolutions, textileSolutions } from '../constants/array'


const Realisations = () => {


    // Lines
    useEffect(() => {
        const canvas = document.getElementById('headerCanvas');
        const ctx = canvas.getContext('2d');
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        let linesArray = [];

        class Line {
            constructor(x, y, length, speed) {
                this.x = x;
                this.y = y;
                this.length = length;
                this.speed = speed;
            }

            update() {
                this.x += this.speed;
                if (this.x > canvas.width) {
                    this.x = 0;
                }
            }

            draw() {
                ctx.strokeStyle = '#1067da62';
                ctx.lineWidth = 2;
                ctx.beginPath();
                ctx.moveTo(this.x, this.y);
                ctx.lineTo(this.x + this.length, this.y);
                ctx.stroke();
            }
        }

        function init() {
            linesArray = [];
            for (let i = 0; i < 50; i++) {
                let x = Math.random() * canvas.width;
                let y = Math.random() * canvas.height;
                let length = Math.random() * 200 + 50;
                let speed = Math.random() * 2 + 0.5;
                linesArray.push(new Line(x, y, length, speed));
            }
        }

        function animate() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            for (let i = 0; i < linesArray.length; i++) {
                linesArray[i].update();
                linesArray[i].draw();
            }
            requestAnimationFrame(animate);
        }

        init();
        animate();

        window.addEventListener('resize', function() {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            init();
        });
    }, []);


    const[audiovIndex, setAudiovIndex] = useState(0)
    const[designIndex, setDesignIndex] = useState(0)
    const[stratIndex, setStratIndex] = useState(0)
    const[textileIndex, setTextileIndex] = useState(0)
    const[digitalIndex, setDigitalIndex] = useState(0)


    return (
        <div>

            {/* HERO */}
            <div className=' bgcolor- d-flex justify-content-center align-items-center position-relative' style={{paddingTop:"30vh", paddingBottom:'10rem'}}>
                <div className=' container h-100'>
                    <h1 
                    data-aos="zoom-in-up" 
                    data-aos-offset="100" 
                    data-aos-duration="700"
                    className='title-size fw-bolder border-title'>
                        Plongez dans nos réalisations : sans 
                        <span className=' gradient-title title-size fw-bolder'> limites</span>
                        , sans 
                        <span className=' gradient-title title-size fw-bolder'> frontières</span> 
                    </h1>
                </div>
                <canvas id='headerCanvas' className='position-absolute top-0 end-0 z-n1 w-100 h-100'></canvas>
            </div>



            {/* Stratégie et Identité de Marque */}
            <section className='container-fluid mb-md-5 mb-3 pb-4 rea-bg-1'>
                <div 
                data-aos="fade-up" 
                data-aos-offset="100" 
                data-aos-duration="1000"  
                className='cs-title mb-3 text-center'>
                    <h2 className='fs-1 fw-bold mb-0'>Stratégie et Identité de Marque</h2>
                    <div className=' minimize-div mx-auto text-center fs-9s'>Construisez une marque qui se démarque dans un océan de concurrents et laissez une empreinte mémorable sur le marché, en créant une connexion authentique avec vos clients !</div>
                    <span className="title-line mx-auto"></span>
                </div>

                <div data-aos="zoom-in" data-aos-offset="100" data-aos-duration="1000">
                    <SolutionsCarousel 
                    indexItem={stratIndex}
                    setIndexItem={setStratIndex}
                    solutionList={strategySolutions}/>
                </div>
            </section>



            
            {/* Accompagnement Digital */}
            <section className='container-fluid mb-md-5 mb-3 pb-4 rea-bg-2'>
                <div 
                data-aos="fade-up" 
                data-aos-offset="100" 
                data-aos-duration="1000"  
                className='cs-title mb-3 text-center'>
                    <h2 className='fs-1 fw-bold mb-0'>Accompagnement Digital</h2>
                    <div className=' minimize-div mx-auto text-center fs-9s'>Découvrez comment chaque défi digital peut devenir une opportunité en or, en optimisant votre présence en ligne avec style et créativité !</div>
                    <span className="title-line mx-auto"></span>
                </div>

                <div data-aos="zoom-in" data-aos-offset="100" data-aos-duration="1000">
                    <SolutionsCarousel 
                    indexItem={digitalIndex}
                    setIndexItem={setDigitalIndex}
                    solutionList={digitalSolutions}/>
                </div>
            </section>



            
            {/* Design & Créa */}
            <section className='container-fluid mb-md-5 mb-3 pb-4 rea-bg-3'>
                <div 
                data-aos="fade-up" 
                data-aos-offset="100" 
                data-aos-duration="1000"  
                className='cs-title mb-3 text-center'>
                    <h2 className='fs-1 fw-bold mb-0'>Design & Créa</h2>
                    <div className=' minimize-div mx-auto text-center fs-9s'>Laissez parler votre imagination avec nous et transformez vos concepts en réalisations inspirantes qui déclenchent l'enthousiasme et l’engagement !</div>
                    <span className="title-line mx-auto"></span>
                </div>

                <div data-aos="zoom-in" data-aos-offset="100" data-aos-duration="1000">
                    <SolutionsCarousel 
                    indexItem={designIndex}
                    setIndexItem={setDesignIndex}
                    solutionList={designSolutions}/>
                </div>
            </section>



            
            {/* Production Audiovisuel */}
            <section className='container-fluid mb-md-5 mb-3 pb-4 rea-bg-4'>
                <div 
                data-aos="fade-up" 
                data-aos-offset="100" 
                data-aos-duration="1000"  
                className='cs-title mb-3 text-center'>
                    <h2 className='fs-1 fw-bold mb-0'>Production Audiovisuel</h2>
                    <div className=' minimize-div mx-auto text-center fs-9s'>L'audiovisuel au service de votre message, pour une communication percutante et mémorable qui fait vibrer votre marque dans le cœur de votre public !</div>
                    <span className="title-line mx-auto"></span>
                </div>

                <div data-aos="zoom-in" data-aos-offset="100" data-aos-duration="1000">
                    <SolutionsCarousel 
                    indexItem={audiovIndex}
                    setIndexItem={setAudiovIndex}
                    solutionList={audiovisualSolutions}/>
                </div>
            </section>



            
            {/* Production Textile */}
            <section className='container-fluid mb-md-5 mb-3 pb-4 rea-bg-5'>
                <div 
                data-aos="fade-up" 
                data-aos-offset="100" 
                data-aos-duration="1000"  
                className='cs-title mb-3 text-center'>
                    <h2 className='fs-1 fw-bold mb-0'>Production Textile</h2>
                    <div className=' minimize-div mx-auto text-center fs-9s'>Des textiles sur mesure pour répondre à vos besoins spécifiques et dynamiser votre marque, en vous assurant que chaque création fait briller votre vision !</div>
                    <span className="title-line mx-auto"></span>
                </div>

                <div data-aos="zoom-in" data-aos-offset="100" data-aos-duration="1000">
                    <SolutionsCarousel 
                    indexItem={textileIndex}
                    setIndexItem={setTextileIndex}
                    solutionList={textileSolutions}/>
                </div>
            </section>



        </div>
    )
}

export default Realisations