import React from 'react'

const Contact = () => {
    return (
        <div>
            
            {/* HERO */}
            <div className=' bgcolor- d-flex justify-content-center align-items-center' style={{paddingTop:"23vh", paddingBottom:'8rem'}}>
                <div className=' container h-100'>
                    <h1 className='title-size fw-bolder'> Besoins de plus d'<span className=' gradient-title title-size fw-bolder'>informations ?</span> </h1>
                </div>
            </div>


            <div className=' container pb-4'>

                {/* Contact Cards */}
                {/* <div className=' row justify-content-center align-items-center'>

                    <div className=' col-lg-4 col-md col-12 mb-md-0 mb-4 pb-4'>
                        <div className=' rounded position-relative border border-2 bordercolor-primary text-center py-5 px-2 shadow contact-card' role='button'>
                            <div className=' position-absolute top-0 start-0 rounded-circle bg-white border border-2 bordercolor-primary d-flex justify-content-center align-items-center icon overflow-hidden' style={{width:'4rem', height:'4rem', marginTop:'-2.3rem', marginLeft:'1rem'}}>
                                <i className=' fa fa-envelope fa-2x'></i>
                            </div>
                            <h4 className=' mb-0 fs-5 fw-bolder'>Email:</h4>
                            <a href={'mailto:'+csEmail} className=' text-black text-break' > {csEmail} </a>
                        </div>
                    </div>

                    <div className=' col-lg-4 col-md col-12 mb-md-0 mb-4 pb-4'>
                        <div className=' rounded position-relative border border-2 bordercolor-primary text-center py-5 px-2 shadow contact-card' role='button'>
                            <div className=' position-absolute top-0 start-0 rounded-circle bg-white border border-2 bordercolor-primary d-flex justify-content-center align-items-center icon overflow-hidden' style={{width:'4rem', height:'4rem', marginTop:'-2.3rem', marginLeft:'1rem'}}>
                                <i className=' fa fa-phone fa-2x'></i>
                            </div>
                            <h4 className=' mb-0 fs-5 fw-bolder'>Téléphone:</h4>
                            <a href={'tel:'+csTel} className=' text-black text-wrap' > {csTel} </a>
                        </div>
                    </div>

                    <div className=' col-lg-4 col-md col-12 mb-md-0 mb-4 pb-4'>
                        <div className=' rounded position-relative border border-2 bordercolor-primary text-center py-5 px-2 shadow contact-card' role='button'>
                            <div className=' position-absolute top-0 start-0 rounded-circle bg-white border border-2 bordercolor-primary d-flex justify-content-center align-items-center icon overflow-hidden' style={{width:'4rem', height:'4rem', marginTop:'-2.3rem', marginLeft:'1rem'}}>
                                <i className=' fa fa-location-dot fa-2x'></i>
                            </div>
                            <h4 className=' mb-0 fs-5 fw-bolder'>Localisation:</h4>
                            <a href={csLocationLink} className=' text-black text-wrap' > {csLocation} </a>
                        </div>
                    </div>
                </div> */}

                <div className=' row justify-content-between align-items-center py-4'>
                    <div className='col-12 mb-md-0 mb-3'>
                        <iframe title='CS COMMUNICATION DOUALA CAMEROUN' className=' border bordercolor-primary rounded border-2 shadow' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d509361.39476450626!2d9.787714!3d4.144273!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x106112a4ffffffff%3A0xd367c56e6cb3dd1c!2sCS%20Communications!5e0!3m2!1sen!2sus!4v1725285556984!5m2!1sen!2sus" width="100%" height="450" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Contact