import React, { useContext } from 'react';

// Import bootstrap classes
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

// Import icon classes
import './assets/fonts/fontawesome/css/all.min.css'

// On scroll apparition
import Aos from 'aos'
import 'aos/dist/aos.css';

// Import carousel style
import 'react-multi-carousel/lib/styles.css';

// Import modal style
import 'react-responsive-modal/styles.css';

// Lazy load style
// import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

// Import style
import './assets/styles/style.css'

// Route dom librairies
import { BrowserRouter } from 'react-router-dom';

import { ParallaxProvider } from 'react-scroll-parallax';

// Component used 
import Header from './components/Header';
import Footer from './components/Footer';
import RoutesWithAnimation from './RoutesWithAnimation';
import Footer2 from './components/Footer2';
import { AppContexte } from './context/context';




function App() {

  const {endPoint} = useContext(AppContexte)

  Aos.init();

  return (
    <BrowserRouter>
      <Header/>
      <ParallaxProvider>
        <RoutesWithAnimation/>
      </ParallaxProvider>
      { endPoint === "/"?<Footer2/>:<Footer/>}
    </BrowserRouter>
  );
}

export default App;
