import React from 'react'
import Carousel from 'react-multi-carousel'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import SolutionCard from './SolutionCard'

const SolutionsCarousel = ({indexItem, setIndexItem, solutionList}) => {

    const responsive = {
        desktop1: {
            breakpoint: { max: 6000, min: 1200 },
            items: 4,
        },
        desktop: {
            breakpoint: { max: 1200, min: 992 },
            items: 4,
        },
        tablet1: {
            breakpoint: { max: 992, min: 768 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 768, min: 576 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 576, min: 0 },
            items: 1,
        }
    }


    return (
        <Tabs selectedIndex={indexItem} onSelect={(index) => setIndexItem(index)}>
            <TabList className={"ps-0"}>
                <ul className="nav nav-pills mb-3 justify-content-center">
                    {
                        solutionList.categories.map((sol,index) =>(
                            <Tab key={index}  className="nav-item">
                                <div
                                role="button" 
                                data-bs-toggle="pill" 
                                data-bs-target={`#strategy-${index}`}
                                className={`nav-link bg-body-secondary px-2 py-1 d-flex align-items-center flex-shrink-0 rounded-pill mb-1 fs-8s mx-1 ${index === 0? "active":null}`}>
                                    <i className=' fa fa-circle color-primary fs-4s me-1'></i>{sol.name}
                                </div>
                            </Tab>
                        ))
                    }
                </ul>
            </TabList>

            {
                solutionList.categories.map((sol,index) =>(
                    <TabPanel key={index}>
                        <Carousel 
                        responsive={responsive} 
                        infinite 
                        showDots 
                        autoPlay 
                        autoPlaySpeed={4000} 
                        draggable 
                        swipeable  
                        keyBoardControl={true} 
                        transitionDuration={1000}
                        centerMode 
                        className='py-4'>
                            {
                                sol.list.map((item, index3) =>(
                                    <SolutionCard 
                                    key={index3} 
                                    description={item.description} 
                                    image={item.image} 
                                    title={sol.name} 
                                    category={sol.name} 
                                    icon={solutionList.icon}
                                    objectif={"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor, aliquid ipsam numquam minus possimus dignissimos ipsum suscipit nihil soluta harum hic, illo nemo ullam repudiandae necessitatibus blanditiis ab officia unde?"}
                                    result={"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor, aliquid ipsam numquam minus possimus dignissimos ipsum suscipit nihil soluta harum hic, illo nemo ullam repudiandae necessitatibus blanditiis ab officia unde? Sapiente, adipisci. Incidunt fugiat ipsum dolore distinctio temporibus. Totam iusto animi perspiciatis nisi explicabo alias deleniti nemo, optio adipisci amet laudantium voluptatum corrupti sint consequuntur in beatae illum! Tenetur, neque? Nesciunt fuga deleniti explicabo soluta atque! "}
                                    />
                                ))
                            }
                        </Carousel>
                    </TabPanel>
                ))
            }
        </Tabs>
    )
}

export default SolutionsCarousel