import Lottie from 'lottie-react'
import React, { useEffect, useRef } from 'react'
import { sparkle } from '../constants/files'

const MainText = ({title, p1, p2, icon}) => {

    const lottieRef = useRef();
    useEffect(() => {
        lottieRef.current.setSpeed(.2)
    }, []);


    return (
        <div className='position-relative'>
            <div className=' position-absolute top-0 end-0  z-n1'>
                <i className={'color-secondary ' + icon} style={{fontSize:"calc(14rem + 20vh)", marginTop:"-5rem"}}></i>
            </div>
            <div className='row justify-content-center align-items-center overflow-hidden flex-wrap-reverse'>
                <div className=' col-lg-7 col-12'>
                    <div data-aos="zoom-out-down" data-aos-anchor-placement="center-bottom" className='position-relative'>
                        <p className='fs-6l'>{p1}
                        <br/>{p2}
                        </p>
                        <Lottie className=' position-absolute top-0 end-0 my-auto z-n1' style={{height:'8rem'}} lottieRef={lottieRef} animationData={sparkle} />
                    </div>
                </div>
                <div className=' col-lg-5 col-12'>
                    <h2 data-aos="fade-up" className='text-capitalize fw-black mb-3 fs-1 gradient-title text-center'>{title}</h2>
                </div>
            </div>
        </div>
    )
}

export default MainText