// import testGif from '../assets/icons/testGif.json'
import designIc from '../assets/icons/graphicDesign.json'
import designGif from '../assets/icons/graphic.json'
import audioVisuelleIc from '../assets/icons/videography.json'
import audioVisuelleGif from '../assets/icons/videoGif.json'
import socialMediaIc from '../assets/icons/socialMedia.json'
import socialMediaGif from '../assets/icons/SocialMediaGif.json'
import webIc from '../assets/icons/webServices.json'
import webGif from '../assets/icons/WebsiteGif.json'

// Hero array
import c1 from '../assets/images/1.jpg'
import c2 from '../assets/images/2.jpg'
import c3 from '../assets/images/3.jpg'
import c4 from '../assets/images/4.jpg'

// Portfolio images
import r1 from '../assets/images/rea/1.png'
import r2 from '../assets/images/rea/2.png'
import r3 from '../assets/images/rea/3.png'
import r4 from '../assets/images/rea/4.jpg'
import r5 from '../assets/images/rea/5.png'
import r6 from '../assets/images/rea/6.jpg'

// Customer logo
import canal2_I from '../assets/images/customer/canal2.png'
import marab_I from '../assets/images/customer/marab.png'
import suntv_I from '../assets/images/customer/suntv.png'
import agc_I from '../assets/images/customer/agc.png'
import rousseau_I from '../assets/images/customer/rousseau.png'
import sounga_I from '../assets/images/customer/sounga.png'
import naija_I from '../assets/images/customer/naija.png'
import micheleIndustry_I from '../assets/images/customer/micheleIndustry.png'
import michele_I from '../assets/images/customer/michele.png'
import mia_I from '../assets/images/customer/mia.png'
import easytech_I from '../assets/images/customer/easytech.png'
import prudential_I from '../assets/images/customer/prudential.png'
import smc_I from '../assets/images/customer/smc.png'
import cimencam_I from '../assets/images/customer/cimencam.png'
import newFoodI_I from '../assets/images/customer/new_food.png'
import keuniFood_I from '../assets/images/customer/keuni_foods_logo.png'
import boissonCameroun_I from '../assets/images/customer/Boissons_du_Cameroun.png'
import huawei_I from '../assets/images/customer/Huawei.png'
import valclair_I from '../assets/images/customer/valclair.png'
import hydrane_I from '../assets/images/customer/hydrane.png'
import mediaPlus_I from '../assets/images/customer/media_plus.png'
import griote_I from '../assets/images/customer/griote.png'
import festivalHumour_I from '../assets/images/customer/caravane_humour.png'

import { graphicLink, socialLink, videoLink, webLink, realisationsLink, contactLink, textileLink } from './links'


export const navLinks = [
    {nav:"L'agence", link:'/'},
    {nav:'Services', subNav:[
        {nav:'Stratégie et Identité de Marque', link:socialLink},
        {nav:'Accompagnement Digital', link:webLink},
        {nav:'Design & Créa', link:graphicLink},
        {nav:'Production Audiovisuel', link:videoLink},
        {nav:'Production Textile', link:textileLink},
    ]},
    {nav:'Portfolio', link:realisationsLink},
    {nav:'Localisation', link:contactLink},
]


export const servicesArray = [
    {icon:designIc, bgIcon:'#fff', title:'Design Graphique', text:'Créer et diffuser des visuels captivants est un essentiel pour ancrer son image de marque aux yeux de son audience. Pour gagner en visibilité et se démarquer durablement, le recours à une agence graphisme s’avère déterminant.', gif:designGif, link:graphicLink},
    {icon:audioVisuelleIc, bgIcon:'#fff', title:'Services Audiovisuel', text:'Passionné, sensible et dévoué. Notre expertise audiovisuelle nous permet de produire, du design à la stratégie de diffusion, des vidéos innovantes, efficaces et originales, garantes du succès de votre communication.', gif:audioVisuelleGif, link:videoLink},
    {icon:socialMediaIc, bgIcon:'#fff', title:'Social Media', text:'Trouver l’équilibre entre une stratégie de contenu engageante et la puissante viralité des publicités, nous permet de positionner nos marques partenaires au coeur de leur cible !', gif:socialMediaGif, link:socialLink},
    {icon:webIc, bgIcon:'#fff', title:'Services web', text:'En tant que véritable spécialiste des solutions web, CS COMMUNICATIONS gère vos projets d’A à Z : création de sites web, développements spécifiques ou système de gestion de contenu.', gif:webGif, link:webLink},
]


export const heroCarouselArray = [
    {img:c3, text:"Plus de 10 années au service des idées"},
    {img:c2, text:"C'est dans la vielle marmite qu'on fait la bonne sauce"},
    {img:c1, text:"Agence digitale depuis 12 ans"},
    {img:c4, text:"Le partenaire idéal pour votre communication digitale"},
]


export const customersLogo = [
    {img:suntv_I, alt:"suntv"},
    {img:sounga_I, alt:"sounga"},
    {img:smc_I, alt:"smc"},
    {img:prudential_I, alt:"prudential"},
    {img:michele_I, alt:"michele"},
    {img:hydrane_I, alt:"hydrane"},
    {img:naija_I, alt:"naija magazine"},
    {img:newFoodI_I, alt:"new Food"},
    {img:marab_I, alt:"maraboo"},
    {img:boissonCameroun_I, alt:"boisson du Cameroun"},
    {img:cimencam_I, alt:"cimencam"},
    {img:canal2_I, alt:"canal2"},
    {img:mia_I, alt:"mia cosmetics"},
    {img:easytech_I, alt:"easy Tech"},
    {img:rousseau_I, alt:"rousseau international school"},
    {img:agc_I, alt:"agc"},
    {img:micheleIndustry_I, alt:"michele Industry"},
    {img:keuniFood_I, alt:"keuni Food"},
    {img:huawei_I, alt:"huawei"},
    {img:griote_I, alt:"griote"},
    {img:festivalHumour_I, alt:"Caravane festival Humour"},
    {img:mediaPlus_I, alt:"media Plus"},
    {img:valclair_I, alt:"valclair"},
]



// Portfolio arrays

export const digitalSolutions = {
    icon:"fa fa-code",
    categories:[
        {name:"Site web",
        list:[
            { image:r1, description:undefined, title:"Titre"},
            { image:r2, description:undefined, title:"Titre"},
        ],
        },
        {name:"Applications mobiles",
            list:[
                { image:r3, description:undefined, title:"Titre"},
                { image:r4, description:undefined, title:"Titre"},
            ],
        },
        {name:"Stratégie digitale",
            list:[
                { image:r6, description:undefined, title:"Titre"},
                { image:r5, description:undefined, title:"Titre"},
            ],
        },
    ]
}



export const strategySolutions = {
    icon:"fa fa-hashtag",
    categories:[
        {name:"Brand Communication",
        list:[
            { image:r1, description:undefined, title:"Titre"},
            { image:r2, description:undefined, title:"Titre"},
        ],
        },
        {name:"Brand Audit",
            list:[
                { image:r3, description:undefined, title:"Titre"},
                { image:r4, description:undefined, title:"Titre"},
            ],
        },
        {name:"Stratégie Digitale",
            list:[
                { image:r6, description:undefined, title:"Titre"},
                { image:r5, description:undefined, title:"Titre"},
            ],
        },
    ]
}



export const designSolutions = {
    icon:"fa fa-pen-nib",
    categories:[
        {name:"Identité Visuelle",
        list:[
            { image:r1, description:undefined, title:"Titre"},
            { image:r2, description:undefined, title:"Titre"},
        ],
        },
        {name:"Edition",
            list:[
                { image:r3, description:undefined, title:"Titre"},
                { image:r4, description:undefined, title:"Titre"},
            ],
        },
        {name:"Création graphique",
            list:[
                { image:r6, description:undefined, title:"Titre"},
                { image:r5, description:undefined, title:"Titre"},
            ],
        },
    ]
}



export const audiovisualSolutions = {
    icon:"fa fa-play",
    categories:[
        {name:"Contenu TV",
        list:[
            { image:r1, description:undefined, title:"Titre"},
            { image:r2, description:undefined, title:"Titre"},
        ],
        },
        {name:"Documentaires",
            list:[
                { image:r3, description:undefined, title:"Titre"},
                { image:r4, description:undefined, title:"Titre"},
            ],
        },
        {name:"Reportages",
            list:[
                { image:r6, description:undefined, title:"Titre"},
                { image:r5, description:undefined, title:"Titre"},
            ],
        },
    ]
}



export const textileSolutions = {
    icon:"fa fa-scissors",
    categories:[
        {name:"Tenues d'entreprise",
        list:[
            { image:r1, description:undefined, title:"Titre"},
            { image:r2, description:undefined, title:"Titre"},
        ],
        },
        {name:"Blouses",
            list:[
                { image:r3, description:undefined, title:"Titre"},
                { image:r4, description:undefined, title:"Titre"},
            ],
        },
        {name:"Boubous",
            list:[
                { image:r6, description:undefined, title:"Titre"},
                { image:r5, description:undefined, title:"Titre"},
            ],
        },
    ]
}