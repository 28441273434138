import Lottie from 'lottie-react';
import React, { useState } from 'react'
import { Parallax } from 'react-scroll-parallax';
import designGif from '../assets/icons/WebsiteGif.json'


import MainText from '../components/MainText';
import { SmartTickerDraggable } from 'react-smart-ticker';
import { customersLogo, digitalSolutions } from '../constants/array';
import SolutionsCarousel from '../components/SolutionsCarousel';

const WebService = () => {


    // Hero animation
    // useEffect(() => {
    //     const canvas = document.getElementById('networkCanvas');
    //     const ctx = canvas.getContext('2d');

    //     canvas.width = canvas.offsetWidth;
    //     canvas.height = canvas.offsetHeight;

    //     const nodes = [];
    //     const nodeCount = 10; // Nombre de nœuds

    //     // Génère des nœuds à des positions aléatoires
    //     for (let i = 0; i < nodeCount; i++) {
    //         nodes.push({
    //             x: Math.random() * canvas.width,
    //             y: Math.random() * canvas.height,
    //             dx: (Math.random() - 0.5) * 2,
    //             dy: (Math.random() - 0.5) * 2
    //         });
    //     }

    //     function drawNodes() {
    //         ctx.clearRect(0, 0, canvas.width, canvas.height);
    //         ctx.strokeStyle = '#1067da67';
    //         ctx.lineWidth = 2;

    //         nodes.forEach((node, index) => {
    //             // Dessine les lignes reliant les nœuds
    //             for (let j = index + 1; j < nodes.length; j++) {
    //                 ctx.beginPath();
    //                 ctx.moveTo(node.x, node.y);
    //                 ctx.lineTo(nodes[j].x, nodes[j].y);
    //                 ctx.stroke();
    //             }

    //             // Dessine les nœuds
    //             ctx.beginPath();
    //             ctx.arc(node.x, node.y, 5, 0, Math.PI * 2);
    //             ctx.fillStyle = '#1067da';
    //             ctx.fill();
    //         });
    //     }

    //     function updateNodes() {
    //         nodes.forEach(node => {
    //             node.x += node.dx;
    //             node.y += node.dy;

    //             // Rebonds sur les bords du canvas
    //             if (node.x < 0 || node.x > canvas.width) node.dx *= -1;
    //             if (node.y < 0 || node.y > canvas.height) node.dy *= -1;
    //         });
    //     }

    //     function animate() {
    //         drawNodes();
    //         updateNodes();
    //         requestAnimationFrame(animate);
    //     }

    //     animate();
    // }, []);

    const[digitalIndex, setDigitalIndex] = useState(0)


    return (
        <div>

            {/* Hero */}
            <section className=' d-flex justify-content-center align-items-center position-relative mb-5' style={{minHeight:"90vh"}}>
                <div className='h-100 position-relative container row justify-content-lg-around justify-content-center flex-wrap-reverse align-items-center'>
                    <h1 className='col-lg-auto col-12 fw-bolder title-size gradient-title position-relative text-center text-lg-start z-1'>Accompagnement <br className=' d-lg-block d-none'/> Digital</h1>
                    <div className=' col-lg col-12 d-flex justify-content-center align-items-center z-n1'>
                        <Parallax easing={'easeInOut'} scale={[0.75, 1]} rotate={[-10,5]} translateY={[-5, 5]} >
                            <Lottie className='lottie-size' style={{marginBottom:'30px'}} animationData={designGif} />
                        </Parallax>
                    </div>
                </div>
                <div className=' position-absolute top-0 end-0 bottom-0 start-0 w-100 h-100 z-n1 imgs-float'>
                    <div className="network-container">
                        <canvas id="networkCanvas"></canvas>
                    </div>
                </div>
            </section>


            {/* main */}
            <main className='mt-5 mb-5 py-md-4 py-4 rea-bg-2'>
                <div className=' container'>
                    <MainText title={"Faites briller votre présence en ligne !"} icon={"fa fa-code"} p1={"Votre site web est bien plus qu'une vitrine : c'est le cœur de votre stratégie digitale. Chez CS Communication, nous créons des expériences en ligne uniques qui captivent vos visiteurs. Notre équipe d'experts conçoit des sites sur mesure, alignés sur vos objectifs, avec un webdesign séduisant, une intégration fluide, et une stratégie de contenu percutante."}/>
                </div>
            </main>


            {/* Customers */}
            <div className=' my-4'>
                <SmartTickerDraggable containerStyle={{height:'6rem', marginLeft:"auto", marginRight:"auto"}} isText={false} pauseOnHover={true} > 
                    <div id='customer-container' className='d-flex flex-nowrap justify-content-center align-items-center mb-3 ms-3' style={{gap:'2rem'}}>
                        {
                            customersLogo.map((elt,index) =>(
                                <div key={index} className='d-flex justify-content-center align-items-center customer-img overflow-hidden' style={{width:'6rem', height:'6rem'}}>
                                    <img className=' img-fluid object-fit-contain' src={elt.img} alt={`Client CS Communication ${elt.alt}`} />
                                </div>
                            ))
                        }
                    </div>
                </SmartTickerDraggable>
            </div>


            <div className=' container my-5'>
                <h2 data-aos="fade-up" data-aos-offset="100" data-aos-duration="1000"  className=' fs-1 cs-title fw-bold mb-2 gradient-title-2  d-inline-block'>Nos Solutions<span className="title-line"></span></h2>

                <div data-aos="zoom-in" data-aos-offset="100" data-aos-duration="1000">
                    <SolutionsCarousel 
                    indexItem={digitalIndex}
                    setIndexItem={setDigitalIndex}
                    solutionList={digitalSolutions}/>

                </div>
            </div>

        </div>
    )
}

export default WebService