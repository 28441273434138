import React, { useEffect, useState } from 'react'
import { Parallax } from 'react-scroll-parallax';
import Lottie from 'lottie-react';

// Mini floating icons
import img1 from '../assets/images/audiovisual/img-1.png'
import img2 from '../assets/images/audiovisual/img-2.png'
import img3 from '../assets/images/audiovisual/img-3.png'
import img4 from '../assets/images/audiovisual/img-4.png'
import img5 from '../assets/images/audiovisual/img-5.png'
import img6 from '../assets/images/audiovisual/img-6.png'
import img7 from '../assets/images/audiovisual/img-7.png'
import img8 from '../assets/images/audiovisual/img-8.png'
import img9 from '../assets/images/audiovisual/img-9.png'

import audiovisualGif from '../assets/icons/videoGif.json'


import MainText from '../components/MainText';
import { SmartTickerDraggable } from 'react-smart-ticker';
import { audiovisualSolutions, customersLogo } from '../constants/array';
import SolutionsCarousel from '../components/SolutionsCarousel';


const Audiovisual = () => {

    // Hero animation
    useEffect(() => {
        
        // Chemins des images
        const imagePaths = [
            img1,
            img2,
            img3,
            img1,
            img2,
            img3,
            img4,
            img5,
            img6,
            img7,
            img8,
            img9,
        ];
    
        // Sélectionner le conteneur d'image
        const container = document.querySelector('.image-appear-container');
    
        // Fonction pour générer une position aléatoire
        function getRandomPosition() {
            const x = Math.random() * container.clientWidth;
            const y = Math.random() * container.clientHeight;
            return { x, y };
        }
    
        // Fonction pour ajouter une image de manière aléatoire
        function addRandomImage() {
            // Créer un élément d'image
            const img = document.createElement('img');
            img.src = imagePaths[Math.floor(Math.random() * imagePaths.length)];
            img.classList.add('image-2');
    
            // Positionner l'image de manière aléatoire
            const { x, y } = getRandomPosition();
            img.style.left = `${x}px`;
            img.style.top = `${y}px`;

            // Appliquer une rotation initiale
            const initialRotation = Math.floor(Math.random() * 180)
            img.style.transform = `rotate(${initialRotation}deg)`
    
            // Ajouter l'image au conteneur
            container.appendChild(img);
    
            // Ajouter des classes pour déclencher l'animation
            setTimeout(() => {
                img.style.opacity = 1;
                img.style.transform = 'translateY(-50px)';
            }, 10); // Petit délai pour permettre l'application du style avant l'animation
    
            // Retirer l'image après une durée aléatoire
            const duration = Math.random() * 5000 + 4000; // entre 2 et 5 secondes
            setTimeout(() => {
                img.style.opacity = 0;
                img.style.transform = `translateY(-${300 + duration*60}px)`;
                // Supprimer l'image du DOM après la transition
                setTimeout(() => {
                    container.removeChild(img);
                }, 2000); // Délai pour la transition de sortie
            }, duration);
        }
    
        // Ajouter des images de manière aléatoire 
        setInterval(addRandomImage, Math.random() * 400 + 600);
    }, []);


    const[audiovIndex, setAudiovIndex] = useState(0)


    return (
        <div>
            

            {/* Hero */}
            <section className=' d-flex justify-content-center align-items-center position-relative' style={{minHeight:"90vh"}}>
                <div className='h-100 position-relative container row justify-content-md-around justify-content-center flex-wrap-reverse align-items-center'>
                    <h1 className='col-md-6 col-12 fw-bolder title-size gradient-title text-mobile-center position-relative text-wrap text-center text-md-start'>Production Audiovisuel</h1>
                    <div className=' col-md-6 col-12 z-n1'>
                        <Parallax easing={'easeInOut'} scale={[0.75, 1]} rotate={[-10,5]} translateY={[-5, 5]} >
                            <Lottie className='lottie-size' style={{marginBottom:'30px'}} animationData={audiovisualGif} />
                        </Parallax>
                    </div>
                </div>
                <div className='image-appear-container imgs-float position-absolute top-0 end-0 bottom-0 start-0 w-100 h-100 z-n1 '></div>
            </section>


            {/* main */}
            <main className='mt-5 mb-5 py-md-4 py-4 rea-bg-2'>
                <div className=' container'>
                    <MainText title={<>Votre vision, <br/>notre vidéo !</>} icon={"fa fa-play"} p1={"Chez CS Communication, chaque idée mérite d'être mise en lumière. Nous rendons la vidéo professionnelle accessible à tous, sans projet trop petit ni rêve trop grand. Notre mission : créer des vidéos qui touchent votre audience. Ensemble, faisons de votre vision une expérience visuelle marquante."} />
                </div>
            </main>



            {/* Customers */}
            <div className=' my-4'>
                <SmartTickerDraggable containerStyle={{height:'6rem', marginLeft:"auto", marginRight:"auto"}} isText={false} pauseOnHover={true} > 
                    <div id='customer-container' className='d-flex flex-nowrap justify-content-center align-items-center mb-3 ms-3' style={{gap:'2rem'}}>
                        {
                            customersLogo.map((elt,index) =>(
                                <div key={index} className='d-flex justify-content-center align-items-center customer-img overflow-hidden' style={{width:'6rem', height:'6rem'}}>
                                    <img className=' img-fluid object-fit-contain' src={elt.img} alt={`Client CS Communication ${elt.alt}`} />
                                </div>
                            ))
                        }
                    </div>
                </SmartTickerDraggable>
            </div>



            <div className=' container my-5'>
                <h2 data-aos="fade-up" data-aos-offset="100" data-aos-duration="1000"  className=' fs-1 cs-title fw-bold mb-2 gradient-title-2 d-inline-block'>Nos Solutions<span className="title-line"></span></h2>

                <div data-aos="zoom-in" data-aos-offset="100" data-aos-duration="1000">
                    
                    <SolutionsCarousel
                    indexItem={audiovIndex}
                    setIndexItem={setAudiovIndex}
                    solutionList={audiovisualSolutions}/>

                </div>
            </div>


        </div>
    )
}

export default Audiovisual