import React, { useContext, useState } from 'react'
import { logo } from '../constants/files'
import { navLinks } from '../constants/array'
import { NavLink, useLocation } from 'react-router-dom'
import { csFacebook, csInstagram, csLinkedIn, csYoutube } from '../constants/links'
import { useEffect } from 'react'
import { AppContexte } from '../context/context';

const Header = () => {

    const {endPoint} = useContext(AppContexte)

    const [bg, setBg] = useState(false)

    window.addEventListener("scroll",()=>{
        if (window.scrollY > 30) {
            setBg(true)
        } else {
            setBg(false)
        }
    })


    const location = useLocation()
    useEffect(() => {
        let  parent = document.getElementById('parent');
        let children = parent.querySelectorAll('.active');
        if (children.length) {
            parent.classList.add('main-nav-link-special')
        }else{
            parent.classList.remove('main-nav-link-special')
        }
    }, [location]);
    

    return (
        <header className=' fixed-top'>
            <div className={'bg-transparent '}>
                <div className={' ' + (bg?'bg-white shadow header-text-black':'')}>
                    <div className=' container'>
                        <nav className=' d-flex justify-content-between align-items-center py-2'>
                            <div className=' col-auto'>
                                <NavLink to={'/'}>
                                    <img src={logo} alt='CS COMMUNICATION' style={!bg?{height:"4.4rem"}:{height:'3rem'}} />
                                </NavLink>
                            </div>
                            <div className='col-auto ms-auto'>
                                <ul className=' d-md-flex d-none mb-0'>
                                    {
                                        navLinks.map((elt, index) =>(
                                            elt.link?
                                                <NavLink key={index} to={elt.link} className={`ms-4 main-nav-link ${endPoint === "/"? "header-text-white":"text-black"}`}> {elt.nav} </NavLink>
                                            :<div id='parent' key={index} className='ps-4 position-relative main-subnav-parent'> 
                                                <span role='button' className={`position-relative main-nav-link pb-1 ${endPoint === "/"? "header-text-white":"text-black"}`}>
                                                    <span>{elt.nav}</span>
                                                    <i className={`ps-2 fa fa-angle-down ${endPoint === "/"? "header-text-white":"text-black"}`}></i>
                                                </span>

                                                <div className='ms-0 position-absolute top-0 mt-4 bg-white shadow px-3 py-2 rounded' style={{width:'19rem'}}>
                                                    {elt.subNav.map((sub,index) =>(
                                                        <NavLink key={index} to={sub.link} className='text-black main-nav-link d-inline-block mb-2'>{sub.nav}</NavLink>
                                                    ))}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </ul>
                                <span role='button' data-bs-toggle="offcanvas" data-bs-target="#mobileNav" className='d-md-none d-block' >
                                    <i className=' fa fa-bars fs-3'></i>
                                </span>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>



            {/* Mobile nav */}
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="mobileNav">
                <div className="offcanvas-header">
                    <h4 className="d-flex align-items-center">
                        <img src={logo} alt='CS COMMUNICATION' className=' pe-3' style={{height:'3rem'}} />
                        CS Communication
                    </h4>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className=' d-flex flex-column justify-content-around h-100'>
                        <ul className=' d-flex flex-column mb-0 ps-0 w-auto'>
                            {
                                navLinks.map((elt, index) =>(
                                    elt.link?
                                    <NavLink key={index} to={elt.link} className='main-nav-link mb-2 d-inline-block' ><span data-bs-dismiss="offcanvas" aria-label="Close">{elt.nav}</span></NavLink>
                                    :<div key={index} className="accordion" id="accordionExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header my-2"><button className="accordion-button border-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"  style={{fontSize:'1.1rem'}}>{elt.nav} </button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                <div className=" ms-4 d-flex flex-column mb-0 ps-0 w-auto">
                                                    {
                                                        elt.subNav.map((sub,index2)=>(
                                                            <NavLink key={index2+6} to={sub.link} className='main-nav-link mb-2 d-inline-block'><span data-bs-dismiss="offcanvas" aria-label="Close">{sub.nav}</span></NavLink>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </ul>

                        <div className=' mt-auto mx-auto'>
                            <div className=' d-flex align-items-center justify-content-center mb-1'>
                                <i className='fa fa-envelope color-primary pe-1'></i>
                                <a className=' text-black fs-6' href='mailto:contact@cscommunications.net'>contact@cscommunications.net</a>
                            </div>
                            <div className=' d-flex align-items-center justify-content-center mb-2'>
                                <i className='fa fa-phone color-primary pe-1'></i>
                                <a className=' text-black fs-6' href='tel:652522020'>6 52 52 20 20</a>
                            </div>
                            <div className='d-flex justify-content-center mb-1'>
                                <div className=' d-flex justify-content-center align-items-center bg-white shadow rounded-circle' style={{width:"2rem", height:"2rem"}}><a href={csFacebook} target='_BLANK' rel='noreferrer' ><i className=' fa-brands fa-facebook-f'></i></a></div>
                                <div className=' d-flex justify-content-center align-items-center bg-white shadow rounded-circle ms-2' style={{width:"2rem", height:"2rem"}}><a href={csInstagram} target='_BLANK' rel='noreferrer' ><i className=' fa-brands fa-instagram'></i></a></div>
                                <div className=' d-flex justify-content-center align-items-center bg-white shadow rounded-circle ms-2' style={{width:"2rem", height:"2rem"}}><a href={csLinkedIn} target='_BLANK' rel='noreferrer' ><i className=' fa-brands fa-linkedin-in'></i></a></div>
                                <div className=' d-flex justify-content-center align-items-center bg-white shadow rounded-circle ms-2' style={{width:"2rem", height:"2rem"}}><a href={csYoutube} target='_BLANK' rel='noreferrer' ><i className=' fa-brands fa-youtube'></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header