import React, { useState } from 'react'
import Modal from 'react-responsive-modal';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { placeholderImg } from '../constants/files';

const SolutionCard = ({ image, description, title, id, icon, category, objectif, result }) => {

    const[open, setOpen] = useState(false)

    return (
        <>
            <div 
            onClick={()=>{setOpen(true)}}
            role='button'
            id={id}
            className=' rounded-3 bg-white shadow overflow-hidden mx-2 realisation-card-2'>
                <div className='img'>
                    <LazyLoadImage
                    alt={"CS Communication - Service " + category + " - " + title} 
                    src={image} 
                    placeholderSrc={placeholderImg}
                    effect="black-and-white"
                    className=' w-100 h-100 object-fit-contain' />
                    <div className=' position-absolute top-0 end-0 w-100 h-100 d-flex flex-column justify-content-end align-items-center text-black p-2 pb-0'> 
                        <p className='mb-0 fs-8s'>{description}</p>
                    </div>
                </div>
                <div className=' p-2 d-flex flex-wrap'>
                    <h3 className=' fs-6 text-secondary d-block w-100 text-center'>{title}</h3>
                </div>
            </div>




            <Modal open={open} onClose={()=>setOpen(false)} center focusTrapped={false} >
                <div className=' row justify-content-around align-items-start'>
                    <img 
                    className='object-fit-contain w-100 h-100 col-md-auto col-12' 
                    style={{maxHeight:"80vh"}}
                    alt='Stratégie Social Média' 
                    src={image} />
                    <div className=' col-md col-12'>
                        <h4 className=' text-center fw-bold color-primary text-capitalize mb-2'>{title}</h4>
                        <div className=' d-flex align-items-center'>
                            <i className={`${icon} color-primary fs-8s`}></i>
                            <span className=' fw-semibold fs-9s ms-1'>Objectif</span>
                        </div>
                        <p className=' fs-9s mb-3'>{objectif}</p>
                        <div className=' d-flex align-items-center'>
                            <i className={`${icon} color-primary fs-8s`}></i>
                            <span className=' fw-semibold fs-9s ms-1'>Résultat</span>
                        </div>
                        <p className=' fs-9s mb-3'>{result}</p>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default SolutionCard