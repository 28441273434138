import placeholder_I from '../assets/images/placeholder.png'


// Customer logo
import canal2_I from '../assets/images/customer/canal2.png'
import marab_I from '../assets/images/customer/marab.png'
import suntv_I from '../assets/images/customer/suntv.png'
import agc_I from '../assets/images/customer/agc.png'
import rousseau_I from '../assets/images/customer/rousseau.png'
import sounga_I from '../assets/images/customer/sounga.png'
import naija_I from '../assets/images/customer/naija.png'
import micheleIndustry_I from '../assets/images/customer/micheleIndustry.png'
import michele_I from '../assets/images/customer/michele.png'
import mia_I from '../assets/images/customer/mia.png'
import easytech_I from '../assets/images/customer/easytech.png'


// Logo RS
import facebookL from '../assets/images/facebook.png'
import instagramL from '../assets/images/instagram.png'

import logo_i from '../assets/images/logo_cs_communication.jpg'
import rocket_i from '../assets/icons/rocket.json'
import sparkle_i from '../assets/icons/sparkle.json'
import star_i from '../assets/icons/star.json'




export const logo = logo_i
export const rocket = rocket_i
export const sparkle = sparkle_i
export const star = star_i

export const placeholderImg = placeholder_I

// Logo customer
export const canal2Logo = canal2_I
export const marabLogo = marab_I
export const suntvLogo = suntv_I
export const agcLogo = agc_I
export const rousseauLogo = rousseau_I
export const soungaLogo = sounga_I
export const naijaLogo = naija_I
export const micheleIndustryLogo = micheleIndustry_I
export const micheleLogo = michele_I
export const miaLogo = mia_I
export const easytechLogo = easytech_I

// Logo RS
export const facebookLogo = facebookL
export const instagramLogo = instagramL