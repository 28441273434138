import React, { useContext, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './views/Home';
import Graphic from './views/Graphic';
import Audiovisual from './views/Audiovisual';
import SocialMedia from './views/SocialMedia';
import WebService from './views/WebService';
import Textile from './views/Textile';
import Contact from './views/Contact';
import Realisations from './views/Realisations';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { contactLink, graphicLink, realisationsLink, socialLink, textileLink, videoLink, webLink } from './constants/links';
import { AppContexte } from './context/context';

const RoutesWithAnimation = () => {
    const {setEndPoint} = useContext(AppContexte)

    const location = useLocation();

    useEffect(() => {
        window.scrollTo({top:0, behavior: 'instant'})
    }, [location]);

    useEffect(() => {
        setEndPoint(location.pathname)
    }, [location, setEndPoint]);

    return (
        <TransitionGroup>
            <CSSTransition key={location.key} timeout={300} classNames={"fade"}>
                <Routes location={location}>
                    <Route path='/'>
                        <Route index element={<Home/>} />
                        <Route path={graphicLink} element={<Graphic/>} />
                        <Route path={videoLink} element={<Audiovisual/>} />
                        <Route path={socialLink} element={<SocialMedia/>} />
                        <Route path={webLink} element={<WebService/>} />
                        <Route path={textileLink} element={<Textile/>} />
                        <Route path={contactLink} element={<Contact/>} />
                        <Route path={realisationsLink} element={<Realisations/>} />
                    </Route>
                </Routes>
            </CSSTransition>
        </TransitionGroup>
    );
}

export default RoutesWithAnimation