import React from 'react'
import { NavLink } from 'react-router-dom'

// timeline style component
import 'react-vertical-timeline-component/style.min.css';
import { heroCarouselArray, navLinks } from '../constants/array'

import Carousel from 'react-multi-carousel';


const Home = () => {



    const responsive = {
        desktop: {
            breakpoint: { max: 6000, min: 768 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 768, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    }

    
    return (
        <div>


            {/* Hero */}
            <section className=' hero-carousel position-relative mb-5'>
                <Carousel 
                responsive={responsive} 
                infinite 
                showDots 
                draggable 
                swipeable 
                autoPlay 
                autoPlaySpeed={7000} 
                keyBoardControl={true} 
                transitionDuration={1000} 
                removeArrowOnDeviceType={["tablet", "mobile"]} 
                className='position-relative overflow-hidden w-100 h-100'>
                    {
                        heroCarouselArray.map((elt,index) =>(        
                            <div className='position-relative w-100 h-100' key={index}>
                                <img className=' object-fit-cover w-100 h-100' src={elt.img} alt={"CS COMMUNICATION " + elt.text}  />
                                <div className=' w-100 h-100 d-flex justify-content-start align-items-center bg-primary bg-opacity-10 position-absolute top-0 end-0 z-2 py-5'>
                                    <div className=' container text-white minimize-div'>
                                        <h1 className=' fw-bold mb-3' style={{textShadow:"2px 1px 3px #000"}}>{elt.text}</h1>
                                        <div className=' d-flex flex-wrap' data-aos="zoom-in-up" data-aos-delay="1100" data-aos-duration="1000">
                                            {
                                                navLinks.map((items) =>(
                                                    items.link?
                                                        null
                                                    :items.subNav.map((sub,index) =>(
                                                        <NavLink key={sub.link+index} to={sub.link} className='fs-8s text-nowrap d-flex align-items-center bg-body-secondary bg-opacity-50 px-2 py-1 me-1 mt-2 rounded-pill fw-medium text-black '><i className=" fa fa-circle fs-5s me-1 color-primary"></i>{sub.nav} </NavLink>
                                                    ))
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Carousel>
            </section>

        </div>
    )
}

export default Home