import React, { useState } from 'react'

export const AppContexte = React.createContext()


export const GlobalApp  = ({children}) =>{

    const[endPoint, setEndPoint]= useState('')



    let update = {
        endPoint: endPoint,
        setEndPoint: setEndPoint,
    }

    return(
        <AppContexte.Provider value={update}>
            {children}
        </AppContexte.Provider>
    )
}