import React from 'react'
import { csFacebook, csInstagram, csLinkedIn, csYoutube } from '../constants/links'

const Footer2 = () => {
    return (
        <footer className='container'>
            <div className=' row justify-content-md-around justify-content-center align-items-center'>
                <div className='col-md-auto mt-auto mx-auto row justify-content-md-start justify-content-center align-items-center mb-md-1 mb-4'>
                    <div className=' col-md-auto d-flex align-items-center justify-content-center mb-md-0 mb-1'>
                        <i className='fa fa-envelope color-primary pe-1 fs-8s'></i>
                        <a className=' text-black fs-9s' href='mailto:contact@cscommunications.net'>contact@cscommunications.net</a>
                    </div>
                    <div className=' col-md-auto d-flex align-items-center justify-content-center mb-md-0 mb-2'>
                        <i className='fa fa-phone color-primary pe-1 fs-8s'></i>
                        <a className=' text-black fs-9s' href='tel:652522020'>6 52 52 20 20</a>
                    </div>
                    <div className='col-md-auto d-flex justify-content-center mb-md-0'>
                        <div className=' d-flex justify-content-center align-items-center bg-white border rounded-circle' style={{width:"1.7rem", height:"1.7rem"}}><a href={csFacebook} target='_BLANK' rel='noreferrer' ><i className=' fa-brands fs-9s fa-facebook-f'></i></a></div>
                        <div className=' d-flex justify-content-center align-items-center bg-white border rounded-circle ms-2' style={{width:"1.7rem", height:"1.7rem"}}><a href={csInstagram} target='_BLANK' rel='noreferrer' ><i className=' fa-brands fs-9s fa-instagram'></i></a></div>
                        <div className=' d-flex justify-content-center align-items-center bg-white border rounded-circle ms-2' style={{width:"1.7rem", height:"1.7rem"}}><a href={csLinkedIn} target='_BLANK' rel='noreferrer' ><i className=' fa-brands fs-9s fa-linkedin-in'></i></a></div>
                        <div className=' d-flex justify-content-center align-items-center bg-white border rounded-circle ms-2' style={{width:"1.7rem", height:"1.7rem"}}><a href={csYoutube} target='_BLANK' rel='noreferrer' ><i className=' fa-brands fs-9s fa-youtube'></i></a></div>
                    </div>
                </div>
                <small className='col-md-auto fs-8s text-black-50 text-center pb-md-0 pb-2 d-flex justify-content-center mb-1'> Copyright &copy; CS COMMUNICATIONS, All Rights Reserved</small>
            </div>
        </footer>
    )
}

export default Footer2